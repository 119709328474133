import {
    MinusCircleOutlined,
    PlusOutlined,
    UploadOutlined,
  } from "@ant-design/icons";
//   import "antd/dist/antd.min.css";
//   import _grid from "./grid";
  import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Card,
    Switch,
    Spin,
    Upload,
    TimePicker,
  } from "antd";
//   import TagsInput from "react-tagsinput";
//   import "react-tagsinput/react-tagsinput.css";
  import lodash from "lodash";
  import { useEffect, useState, useRef } from "react";
//   import axios from "axios";
//   import moment from "moment";
  import dayjs from "dayjs";
  import { useCallback } from "react";
//   import { gql } from "@apollo/client";
//   import { QueryRequest } from "@services/apollo/api_service";
//   import { getUUID } from "@helpers/uuid";
//   import { showNotification } from "@helpers/notify";
  
  const { TextArea } = Input;
  const { Option } = Select;
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


const FormSelect = (props) => {
    let options = props.options;
    return (
      <Form.Item
        label={props.label}
        name={props.field}
        rules={props.rules}
        // style={props.style}
        // hidden={true}
      >
        <Select
          disabled={props.disabled}
          mode={props.isMulti ? "multiple" : "single"}
          loading={props.loading}
          showSearch={props.showSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder={props.form_type === "profile" ? "" : ""}
        >
          {options?.map((item, index) => {
            let newObj = {};
            newObj["value"] = lodash.get(item, props.valueField || "id", null);
            newObj["label"] = lodash.get(item, props.labelField || "name", null);
  
            return (
              <Option
                // notFoundContent={<Spin size="small" />}
                key={index}
                value={newObj["value"]}
              >
                {newObj["label"]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
}
    const VForm = (props) => {
        const { children } = props;
        const [form] = Form.useForm();
        const [initialValues, setInitialValues] = useState(props.initialValues);
        useEffect(() => {
          form.setFieldsValue(props.initialValues);
          setInitialValues(props.initialValues);
        }, [form, props.initialValues]);
      
        const handleOnValueChange = (value, values) => {
          if (props.onValueChange) {
            props.onValueChange(value, values);
          }
        };
      
        const handleOnFinish = (values) => {
          if (props.onFinish) {
            props.onFinish(values);
          }
        };
        return (
          <Form
            form={form}
            name={props.id}
            layout={"vertical"}
            onFinish={handleOnFinish}
            onValuesChange={handleOnValueChange}
            initialValues={initialValues}
          >
            {children}
          </Form>
        );
      };
    //   VForm.Grid = Grid;
    //   VForm.List = List;
    //   VForm.TextBox = TextBox;
    //   VForm.TagBox = TagBox;
    //   VForm.Number = Number;
    //   VForm.TextArea = FormTextArea;
    //   VForm.Password = Password;
      // VForm.IntlPhone = IntlPhone;
    //   VForm.Phone = Number;
      VForm.Select = FormSelect;
      // VForm.Cascade = Cascade;
    //   VForm.FormFile = File;
    //   VForm.Date = Date;
    //   VForm.Time = Time;
    //   VForm.Radio = FormRadio;
    //   VForm.CheckBox = CheckBox;
    //   VForm.Button = FormButton;
    //   VForm.Switch = FormSwitch;
    //   VForm.FormGroupSelect = FormGroupSelect;
      export default VForm;