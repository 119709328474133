import { Box, HStack } from 'native-base'
import React from 'react'
import SideMenuLayout from '../layout/sidemenu_layout'
import OnboardingEmployee from '../pages/onboarding_employee'
import Header from '../layout/Header'
import OnboardingClient from '../pages/onboarding_client'
import OnboardingLogistics from '../pages/onboarding_logistics'
import OnboardingOil from '../pages/onboarding_oil'

const OilModule = () => {
  return (
    <div>
      <Header />
      <HStack>
        <Box>
        <SideMenuLayout />
        </Box>
       <Box w={"70%"}>
       <OnboardingOil />
       </Box>
        
      </HStack>
    </div>
  )
}

export default OilModule
