import React from 'react';
import '../components/common/dashboard.css'
import { Box } from 'native-base';
const DashboardTile = ({ title, value, color1, color2, color3 }) => {
  return (
    <Box m={"auto"} w={"1/3"} h={"200px"} style={{
        borderWidth: 1, // Border width
        borderColor: '#ccc', // Border color
        borderRadius: 8, // Border radius
        padding: 20,
        marginBottom: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2, // Shadow for Android
        backgroundImage: `linear-gradient(to right, ${color1}, ${color2})`,
      }}>
      <h3>{title}</h3>
      <p>{value}</p>
    </Box>
  );
};

export default DashboardTile;
