import { Box, HStack } from 'native-base'
import React from 'react'
import SideMenuLayout from '../layout/sidemenu_layout'
import OnboardingEmployee from '../pages/onboarding_employee'
import Header from '../layout/Header'
import OnboardingClient from '../pages/onboarding_client'
import OnboardingVendor from '../pages/onboarding_vendor'

const VendorModule = () => {
  return (
    <div>
      <Header />
      <HStack>
        <Box>
        <SideMenuLayout />
        </Box>
       <Box w={"70%"}>
       <OnboardingVendor />
       </Box>
        
      </HStack>
    </div>
  )
}

export default VendorModule
