import { Button, Form, Input, Select } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "../components/antd_form";
const { TextArea } = Input;
const UnloadingForm = () => {
  const [vendors,setVendors] = useState([]);
  const [oils,setOils] = useState([]);
  const [storageUnits,setStorageUnits] = useState([]);
  const [logistics,setLogistics] = useState([]);
  useEffect(() => {
		fetch("http://localhost:5000/vendor").then((response) => response.json()).then(data => setVendors(data)); 
    fetch("http://localhost:5000/petrol-types").then((response) => response.json()).then(data => setOils(data));
    fetch("http://localhost:5000/storage-unit").then((response) => response.json()).then(data => setStorageUnits(data));
    fetch("http://localhost:5000/logistic").then((response) => response.json()).then(data => setLogistics(data));
  },[]);
  const handleSubmit = async(values) => {
    console.log("hg",values);
      try {
        const response = await fetch("http://localhost:5000/transfer/unloading", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
    
        const result = await response.json();
        console.log("Success:", result);
        // setToaster('success');
      } catch (error) {
        console.error("Error:", error);
        // setToaster('error');
      }
  }
  return (
    <VStack p={5} margin={5}>
      <Form layout={"vertical"} onFinish={handleSubmit}>
        <Box>
          <Form.Item
            label="VENDOR NAME"
            name="vendor_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}  
          >
            <VForm.Select  field="vendor_id"
                    labelField={"name"}
                    valueField={"vendor_id"}
                    options={vendors} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item
            label="OIL TYPE"
            name="petrol_type_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="petrol_type_id"
                    labelField={"oil_name"}
                    valueField={"petrol_type_id"}
                    options={oils} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"quantity"} label="QUANTITY (KGS)">
            <Input placeholder="0" />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item
            label="DELIVERY / LOGISTICS"
            name="logistic_service_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="logistic_service_id"
                    labelField={"organization_name"}
                    valueField={"logistic_service_id"}
                    options={logistics} />
          </Form.Item>
          </Box>
        <Box>
          <Form.Item
            label="STORAGE UNIT TYPE"
            name="storage_unit_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="storage_unit_id"
                    labelField={"type"}
                    valueField={"storage_unit_id"}
                    options={storageUnits} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"no_of_storage_unit"} label="NO. OF STORAGE UNITS REQUIRED">
            <Input placeholder="0" />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"unloading_purpose"} label="Purpose">
            <TextArea rows={3} />
          </Form.Item>
        </Box>
     
      <Box>
        <Form.Item name={"vehicle_no"} label="VEHICLE NUMBER">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"driver_name"} label="DRIVER NAME">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"done_by"} label="LOADING DONE BY">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"gate_pass_no"} label="GATE PASS NUMBER">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box mt={3}>
        <HStack m={"auto"} space={5} >
        <Box>
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    UNLOADING INITIATED
                  </Text>
                </Button>
              </Box>
              <Box >
                <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    UNLOADING DONE
                  </Text>
                </Button>
                </Form.Item>
              </Box>
        </HStack>
      
      </Box>
      </Form>
    </VStack>
  );
};

export default UnloadingForm;
