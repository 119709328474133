import React, { createRef } from "react";
import lodash from "lodash";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import NotFound from "../components/common/not_found";
import myRoutes from "./my_routes";
import { useCheckLogin, usePageAccess } from "../../helpers/auth";
import NotAllowed from "../components/common/not_allowed";
// import { ProjectName } from "helpers/constants";

export const ProjectName = "Rasha Petroleum";
export const apiUrl = "http://localhost:5000";

export const historyRef = createRef();

export const Router = () => {
    console.log("ROUTES",myRoutes);
  let paths = [];
  const getPaths = (routes, parent_keys = []) => {
    for (let i = 0; i < routes.length; i++) {
      let _route = routes[i];
      let _key = _route.key;
      let _parent_keys = [...parent_keys, _key];
      if (_route.path) {
        paths.push({ path: _route.path, keys: _parent_keys });
      } else {
        paths = getPaths(_route.children, _parent_keys);
      }
    }
    return paths;
  };
  paths = getPaths(myRoutes, []);
console.log("path",paths);
  const MyRoute = (my_route_props) => { 
    const { pathname } = useLocation();
    let url = pathname.split("/");
    if (url[1]) {
      let path = lodash.startCase(lodash.camelCase(url[1]));
      document.title = path + " | " + ProjectName;
    } else document.title = ProjectName;

    if (!my_route_props.authenticate) {
      return <>{my_route_props.children}</>;
    }
    // const isLoggedIn = useCheckLogin();
    // const hasAccess = usePageAccess(my_route_props.my_route_key);
    // if (isLoggedIn && hasAccess) {
    //   return <>{my_route_props.children}</>;
    // }
    // if (!isLoggedIn || !hasAccess) {
    //   return <NotAllowed />;
    // }
  };

//   const getRoutes = (routers, not_found) => {
//     console.log("ROUTEccS",routers);

//     return (
//         <>
//         {routers.map((router) => {
//           if (router.path) {
//             let Component = router.component;
//             return (
//               <Route
//                 exact={router.exact}
//                 key={`${router.key}`}
//                 path={router.path}
//               >
//                 <MyRoute
//                   my_route_key={router.key}
//                   authenticate={router.authenticate}
//                 >
//                   <Component {...router} />
//                 </MyRoute>
//               </Route>
//             );
//           } else {
//             let _paths = paths
//               .filter((x) => x.keys.indexOf(router.key) > -1)
//               .map((x) => x.path);
//             let Component = router.component;
//             let _children = getRoutes(router.children);
//             return (
//               <Route exact={router.exact} key={`${router.key}`} path={_paths}>
//                 <MyRoute
//                   my_route_key={router.key}
//                   authenticate={router.authenticate}
//                 >
//                   <Component>{_children}</Component>
//                 </MyRoute>
//               </Route>
//             );
//           }
//         })}
//          {not_found && <Route path="*" component={NotFound} />}
//          </>
//          );
//   };

  const getRoutes = (routers, not_found) => {
    return (
      <Routes>
        {routers.map((router) => {
          console.log(router);
          if (router.path) {
            let Component = router.component;
            return (
              <Route
                path={router.path}
                element={
                  <MyRoute
                    my_route_key={router.key}
                    authenticate={router.authenticate}
                  >
                    <Component {...router} />
                  </MyRoute>
                }
                key={`${router.key}`}
              />
            );
          } 
          else {
            let _paths = paths
              .filter((x) => x.keys.indexOf(router.key) > -1)
              .map((x) => x.path);
              console.log("_path",_paths,router,paths);
            let Component = router.component;
            let _children = getRoutes(router.children);
            console.log(_children);
            return (
              <Route
                path={_paths[0]}
                element={
                  <MyRoute
                    my_route_key={router.key}
                    authenticate={router.authenticate}
                  >
                    <Component>{_children}</Component>
                  </MyRoute>
                }
                key={`${router.key}`}
              />
            );
          }
        })}
        {not_found && <Route path="*" element={<NotFound />} />}
      </Routes>
    );
  };

  const BrowserRoute = () => {
    getRoutes(myRoutes, "not_found");
    const history = useNavigate();
    historyRef.current = history;
    console.log(getRoutes(myRoutes,"not_found"));
    let _children = getRoutes(myRoutes, "not_found");
    return <>{_children}</>;
  };

  return (
    <BrowserRouter>
      <BrowserRoute />
    </BrowserRouter>
  );
};
