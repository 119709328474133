import React from "react";
import { Box, VStack } from "native-base";
import { ROUTES } from "../routes/my_routes";
import SideBar from "../components/side_bar";
import SideBarHeader from "../components/sidebar_header";
import SideBarFooter from "../components/sidebar_footer";
import {
 
  MdOutlineDashboard,
  MdOutlineSettings,
  MdMenu,
  MdOutlineOilBarrel,
  MdOutlinePeopleAlt,
  MdOutlineLocalGasStation
} from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { FaTruckDroplet,FaPersonChalkboard } from "react-icons/fa6";

import {
  IoManSharp,
  IoLocationOutline,
  IoPeopleOutline,
} from "react-icons/io5";

import { TbHomeEdit, TbReceiptTax } from "react-icons/tb";
import { BiPackage, BiTransferAlt } from "react-icons/bi";
import sidebar_image from "../layout/background.jpg";

import {
  MdSubscriptions,
  MdStore,
  MdVerified,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";

// import HeaderBar from "@views/components/ui/header_bar/header_bar";
// import FooterBar from "@views/components/ui/footer_bar";
// import ScrollView from "@views/components/ui/scroll_view";
// import { useTranslation } from "react-i18next";
// import { retrieveItem } from "@helpers/storage";

const SideMenuLayout = ({ children }) => {
  let menu = [];
//   const roles = retrieveItem("role");
//   const {  } = useTranslation();
  menu = [
    {
      title: ("Dashboard"),
      icon: <MdOutlineDashboard />,
      url: ROUTES.HOME,
    },
    {
      title: ("Purchase"),
      icon: <BiPackage />,
      url: ROUTES.PURCHASE,
    },
    {
      title: ("Sales"),
      icon: <MdOutlineProductionQuantityLimits />,
      url: ROUTES.SALES,
    },
    {
      title: ("Transfer"),
      icon: <BiTransferAlt />,
      url: ROUTES.TRANSFER,
    },
    {
      title:("Oil"),
      icon: <MdOutlineOilBarrel />,
      url: ROUTES.OIL,
  },
  {
    title: ("People"),
    icon: <MdOutlinePeopleAlt />,
    subMenu:[
      {
        title: ("Client"),
        icon: <FaPersonChalkboard />,
        url: ROUTES.CLIENT_LIST,
      },
      {
        title: ("Vendor"),
        icon: <MdOutlineLocalGasStation />,
        url: ROUTES.VENDOR_LIST,
      },
      {
        title: ("Employee"),
        icon: <GrUserWorker />,
        url: ROUTES.EMPLOYEE_LIST,
      },
      {
        title: ("Logistics Service"),
        icon: <FaTruckDroplet />,
        url: ROUTES.LOGISTICS_LIST,
      },
    ]
  }
    
   
    // {
    //   title: ("master_data"),
    //   icon: <MdMenu />,
    //   // url: ROUTES.VENDOR_ORDER_LIST,
    //   subMenu: [
    //     {
    //       title: ("categories"),
    //       icon: <MdOutlineCategory />,
    //       url: ROUTES.CATEGORY,
    //     },
    //     {
    //       title: ("state"),
    //       icon: <IoLocationOutline />,
    //       url: ROUTES.STATE_LIST,
    //     },
    //     {
    //       title: ("form:customer"),
    //       icon: <IoPeopleOutline />,
    //       url: ROUTES.CUSTOMER_LIST,
    //     },
    //     {
    //       title: ("common:vendor"),
    //       icon: <MdOutlineLocalShipping />,
    //       url: ROUTES.VENDOR_LIST,
    //     },
    //   ],
    // },
    // {
    //   title: ("settings"),
    //   icon: <MdOutlineSettings />,
    //   // url: ROUTES.CUSTOMER_LIST,
    //   subMenu: [
    //     {
    //       title: ("taxes"),
    //       icon: <TbReceiptTax />,
    //       url: ROUTES.TAX,
    //     },
    //   ],
    // },
  ];

//   const filteredMenu = roles === "vendor" ? menu.slice(0, 3) : menu;

  return (
    <Box flexDirection="row" flexGrow="1">
      <SideBar
        image={
          // sidebar_image
          // "https://climate.copernicus.eu/sites/default/files/styles/hero_image_extra_large_2x/public/2018-07/globalagriculture.jpg?itok=fUmFHs6s"
          // "https://wallpapercave.com/uwp/uwp4255240.png"
          "https://wallpapercave.com/wp/wp8203981.jpg"
        }
        iconShape="round"
        // iconBgColor={"none"} 
        iconColor={"#ffffff"}
        iconSize={25}
        iconSelectedColor={"#330000"}
        // backColor={"#ffffff"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        headerHeight={"10px"}
        footerHeight="50px"
        menu={menu}
        // headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
        projectName={"Rasha Petroleum"}
      />
      <VStack flex={1}>
        {/* <HeaderBar projectName={""} name={roles} /> */}
        {/* <ScrollView flex={1}> */}
          <Box flex={1} ml="10px" mr="10px" px="30px">
            {children}
          </Box>
        {/* </ScrollView> */}
        {/* <FooterBar /> */}
      </VStack>
    </Box>
  );
};
export default SideMenuLayout;
