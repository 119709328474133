import React, { useEffect, useState } from "react";

import { Box, VStack, Text, HStack } from "native-base";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import SearchBox from "../ui/search_box/search_box";

import {
	Breadcrumb,
	Pagination,
	Popconfirm,
	Table,
	Typography,
	Space,
	Tooltip,
} from "antd";
import ActionButton from "../components/action_button";
import { ROUTES } from "../routes/my_routes";
import { useNavigate } from "react-router-dom";

const UnloadingList = () => {

   
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const [current, setCurrent] = useState(1);
	const [unloads, setUnloads] = useState([]);

	// const {
	// 	items: get_all_countries,
	// 	loading,
	// 	pagination,
	// } = useDynamicSelector("get_all_countries");

	// const {
	// 	loading: deleteLoading,
	// 	status: deleteStatus,
	// 	error: deleteError,
	// } = useDynamicSelector("delete_country");
	useEffect(() => {
		fetch("http://localhost:5000/transfer/unloading").then((response) => response.json()).then(data => setUnloads(data));
		console.log(unloads);
	}, []);
	useEffect(() => {
		// let keys = [{ key: "get_all_countries", loading: true }];
		// dispatch(
		// 	dynamicRequest(keys, get_all_countries_query, {
		// 		page_number: current,
		// 		page_limit: 10,
		// 	})
		// );
	}, []);

	// useEffect(() => {
	// 	if (deleteStatus === "success") {
	// 		showNotification({
	// 			type: "success",
	// 			message: `${"Country"} deleted successfully`,
	// 		});
	// 		dispatch(dynamicClear("create_country"));

	// 		let keys = [{ key: "delete_country", loading: true }];

	// 		dispatch(
	// 			dynamicRequest(keys, get_all_countries_query, {
	// 				page_number: current,
	// 				page_limit: 10,
	// 			})
	// 		);
	// 	}
	// }, [deleteStatus]);

	const fetchData = (value, values) => {
		// let keys = [{ key: "get_all_countries", loading: true }];
		// dispatch(
		// 	dynamicRequest(keys, get_all_countries_query, {
		// 		page_number: value,
		// 		page_limit: values,
		// 	})
		// );
		// setCurrent(value);
	};

	const handleAddSale = () => {
		// history.push({
		// 	pathname: ROUTES.CREATE_SALES,
		// 	state: {
		// 		title: "Add",
		// 	},
		// });
		// console.log("pressed");
		navigate(ROUTES);
	};

	const handleEditCountry = (item) => {
		// history.push({
		// 	pathname: `${ROUTES.COUNTRY_DETAILS}/${item.id}`,
		// 	state: {
		// 		title: "Edit",
		// 		country_item: item,
		// 	},
		// });
	};

	const handleDeleteCountry = (record) => {
		// let keys = [{ key: "delete_country", loading: true }];
		// dispatch(
		// 	dynamicRequest(keys, delete_country_mutation, {
		// 		id: record?.id,
		// 	})
		// );
	};

	// const handleSearch = (record) => {
	// 	let keys = [{ key: "get_all_countries", loading: true }];
	// 	dispatch(
	// 		dynamicRequest(keys, get_all_countries_query, {
	// 			page_number: current,
	// 			page_limit: 10,
	// 			country_name: record,
	// 		})
	// 	);
	// };

	
    let columns = [
		{
			title: ("Vendor Name"),
			dataIndex: "vendor_name",
			align: "left",
			key: "vendor_name",
		},
		{
			title: ("Oil Name"),
			dataIndex: "oil_name",
			align: "left",
			key: "oil_name",
		},
		{
			title: ("Logistics Service"),
			dataIndex: "log_company_name",
			align: "left",
			key: "log_company_name",
		},
		{
			title: ("Vehicle No"),
			dataIndex: "vehicle_no",
			align: "left",
			key: "vehicle_no",
		},
		{
			title: ("Driver Name"),
			dataIndex: "driver_name",
			align: "left",
			key: "driver_name",
		},
		// {
		// 	title: ("Status"),
		// 	dataIndex: "status",
		// 	align: "left",
		// 	key: "status",
		// },
		// {
		// 	title: ("Actions"),
		// 	dataIndex: "",
		// 	key: "actions",
		// 	type: "actions",
		// 	align: "center",
		// 	render: (record) => {
		// 		return (
		// 			<span style={{}}>
		// 				<Space>
		// 					<Typography.Link onClick={() => handleEditCountry(record)}>
		// 						<Space>
		// 							<Tooltip title="Edit" placement="bottom">
		// 								<EditOutlined />
		// 							</Tooltip>
		// 						</Space>
		// 					</Typography.Link>
		// 					<Popconfirm
		// 						title="Sure to delete?"
		// 						onConfirm={() => handleDeleteCountry(record)}
		// 					>
		// 						<Space>
		// 							<Tooltip title="Delete" placement="bottom">
		// 								<DeleteOutlined />
		// 							</Tooltip>
		// 						</Space>
		// 					</Popconfirm>
		// 				</Space>
		// 			</span>
		// 		);
		// 	},
		// },
	];
    let header_actions = [
		{
			height: "50px",
			label: ("Add New"),
			colorScheme: "success",
			variant: "outline",
			onPress: handleAddSale,
		},
	];

  return (
    <Box width="100%" mt="20px" marginBottom="20px" p={3}>
    <VStack space={5}>
        <Box>
            {/* <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/"> Home</a>
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                    <a href={ROUTES.COUNTRY_LIST}>{("countries")}</a>
                </Breadcrumb.Item>
            </Breadcrumb> */}
        </Box>
        <Box position="sticky" top="0px" zIndex="2" bg="white">
            <Text fontSize="xl" color={"#FF5F5F"} fontWeight="bold">
               Unloading
            </Text>
        </Box>
        <HStack space={3} justifyContent="flex-end">
            {/* <SearchBox label="Search" width={"200px"} onSearch={handleSearch} /> */}
            {header_actions.map((headerAction, index) => (
                <Box key={`header-action-${index}`}>
                    <ActionButton {...headerAction} />
                </Box>
            ))}
        </HStack>
        {/* <Wrapper> */}
            <Table
                bordered={true}
                columns={columns} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                dataSource={unloads}
                pagination={false}
                // loading={loading}
            />
        {/* </Wrapper> */}
        <Pagination
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            pageSizeOptions={[1, 2, 10]}
            // total={pagination?.total_count}
			total={10}
        />
    </VStack>
</Box>
  )
}

export default UnloadingList


