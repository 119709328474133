import { Box, Center, HStack, Text, VStack } from 'native-base'
// import { DatePicker } from 'antd';
import { Button, DatePicker, Form, Input, Radio, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'

const OnboardingEmployee = () => {
    const [form] = Form.useForm();
    const [toaster,setToaster] = useState('');
    const handleSubmit = async(values) => {
      console.log("hg",values);
        try {
          const response = await fetch("http://localhost:5000/employee", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
      
          const result = await response.json();
          console.log("Success:", result);
          setToaster('success');
          
        } catch (error) {
          console.error("Error:", error);
          setToaster('error');
        }
    }
    const [api, contextHolder] = notification.useNotification();
    const openSuccessNotificationWithIcon = (type) => {
      api[type]({
        message: 'Onboarding Completed Successfully',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    const openErrorNotificationWithIcon = (type) => {
      api[type]({
        message: 'Onboarding Failed',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    useEffect(()=>{
      if(toaster == 'success'){
        openSuccessNotificationWithIcon('success');
        // form.resetFields();
      }else if(toaster == 'error'){
        openErrorNotificationWithIcon('error');
      }
    },[toaster])
  return (
    <Box>
      {/* <Header /> */}
      {contextHolder}
      <Box>
        <Center _text={{ fontSize: 20, fontWeight: "bold" }}>
          Onboarding Employee
        </Center>
        <Form  form={form} layout={"vertical"} onFinish={handleSubmit}>
          <Box p={5}>
            <Text fontSize={20} fontWeight={"bold"}>
              Personal Details
            </Text>
            <VStack p={4}>
              <HStack space={"5"}>
                <Box>
                  <Form.Item name={"first_name"} label="First Name">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"last_name"} label="Last Name">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please Select an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="male">Male</Radio.Button>
                      <Radio.Button value="female">Female</Radio.Button>
                      <Radio.Button value="other">Other</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"5"}>
                <Box>
                  <Form.Item name={"phone_no"} label="Phone No.">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"email_address"} label="Email address">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"date_of_birth"} label="Date of birth">
                    <DatePicker />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"city"} label="City">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"5"}>
                <Box>
                  <Form.Item name={"address_line_1"} label="Address line 1">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"address_line_2"} label="Address line 2">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"pincode"} label="Pincode">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"country"} label="Country">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"5"}>
                <Box>
                  <Form.Item name={"aadhar_no"} label="Aadhar No.">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"pan_no"} label="PAN No.">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
            </VStack>
            <Text fontSize={20} fontWeight={"bold"}>
              Employee Registration
            </Text>
            <Box>
              <VStack p={4}>
                <HStack space={"5"}>
                  <Box>
                    <Form.Item name={"name"} label="Name">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"organization_name"} label="Name of the Organization">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"company_mail_id"} label="Company Mail id">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"company_phone_no"} label="Company Phone No.">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack>
                <HStack space={"4"}>
                  <Box>
                    <Form.Item name={"product_category_and_nature_of_business"} label="Product Category & Nature of Business">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"date_of_incorporation"} label="Date of incorporation">
                      <DatePicker />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"registered_office_address"} label="Registered Office Address">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"company_city"} label="City">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack>
                <HStack space={"4"}>
                  <Box>
                    <Form.Item name={"company_country"} label="Country">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"company_pincode"} label="Pin Code">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"sap_code"} label="SAP Code">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack>
              </VStack>

              {/* <Text fontSize={20} fontWeight={"bold"}>
                GSTN Details
              </Text> */}
              <VStack p={4}>
                {/* <HStack space={"5"}>
                  <Box>
                    <Form.Item label="State">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item label="GSTN.No">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack>
                <HStack space={"5"}>
                  <Box>
                    <Form.Item label="Contact  Details 1">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item label="Contact  Details 2">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack>
                <HStack space={"5"}>
                  <Box>
                    <Form.Item label="Email address">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item label="Contact">
                      <Input placeholder="Type" />
                    </Form.Item>
                  </Box>
                </HStack> */}
                {/* <Text>
                  Are You Registered With? (Applicable For Indian Bidder)
                </Text>
                <HStack space={"1/5"}>
                  <Box>
                    <Form.Item
                      name="radio-msme"
                      label="MSME"
                      rules={[
                        {
                          required: true,
                          message: "Please Select an item!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item label="MSME Number">
                      <Input placeholder="Enter MSME Number " />
                    </Form.Item>
                  </Box>
                </HStack>
                <HStack space={"1/5"}>
                  <Box>
                    <Form.Item
                      name="radio-ssi"
                      label="SSI"
                      rules={[
                        {
                          required: true,
                          message: "Please Select an item!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio.Button value="yes">Yes</Radio.Button>
                        <Radio.Button value="no">No</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item label="SSI Number">
                      <Input placeholder="Enter SSI Number " />
                    </Form.Item>
                  </Box>
                </HStack> */}
                <Text color={"#FF5F5F"} fontSize={"20px"}>Certification & Self Declaration</Text>
              <Box p={4}>
              <Text mb={3}>
                I/We agree to comply with strict adherence to the Company’s
                policies and procedures, and other applicable Statutory Laws,
                throughout the term of service.
              </Text>
              <Radio value="auth">
                {" "}
                To be signed by Authorised Representative{" "}
              </Radio>
              </Box>
              <Box m={"auto"}>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    Submit
                  </Text>
                </Button>
                </Form.Item>
              </Box>
              </VStack>
             
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default OnboardingEmployee
