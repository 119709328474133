import { Button, Form, Input, Select, notification } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import VForm from "../components/antd_form";
const { TextArea } = Input;
const TransferForm = () => {
  const [oils,setOils] = useState([]);
  const [storageUnits,setStorageUnits] = useState([]);
  const [logistics,setLogistics] = useState([]);
  const [stations,setStations] = useState([]);
  const [toaster,setToaster] = useState('');

  useEffect(() => {
    fetch("http://localhost:5000/petrol-types").then((response) => response.json()).then(data => setOils(data));
    fetch("http://localhost:5000/storage-unit").then((response) => response.json()).then(data => setStorageUnits(data));
    fetch("http://localhost:5000/logistic").then((response) => response.json()).then(data => setLogistics(data));
    fetch("http://localhost:5000/stations").then((response) => response.json()).then(data => setStations(data));
  },[]);
  const handleSubmit = async(values) => {
    console.log("hg",values);
      try {
        const response = await fetch("http://localhost:5000/transfer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
    
        const result = await response.json();
        console.log("Success:", result);
        setToaster('success');
      } catch (error) {
        console.error("Error:", error);
        setToaster('error');
      }
  }
  const [api, contextHolder] = notification.useNotification();
    const openSuccessNotificationWithIcon = (type) => {
      api[type]({
        message: 'Transfer Done Successfully',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    const openErrorNotificationWithIcon = (type) => {
      api[type]({
        message: 'Transfer Failed',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
  return (
    <VStack p={5} margin={5}>
      <Form layout={"vertical"} onFinish={handleSubmit}>
      <Box>
      {contextHolder}
          <Form.Item
            label="OIL TYPE"
            name="petrol_type_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="petrol_type_id"
                    labelField={"oil_name"}
                    valueField={"petrol_type_id"}
                    options={oils} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"quantity"} label="QUANTITY (KGS)">
            <Input placeholder="0" />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item
            label="DELIVERY / LOGISTICS"
            name="logistic_service_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="logistic_service_id"
                    labelField={"organization_name"}
                    valueField={"logistic_service_id"}
                    options={logistics} />
          </Form.Item>
          </Box>
          
            <HStack p={3} space={"2"}>
              <VStack w={"1/2"}>
              <Box>
          <Form.Item
            label="PLANT"
            name="station_1_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="station_1_id"
                    labelField={"name"}
                    valueField={"station_id"}
                    options={stations} />
          </Form.Item>
          </Box>
          <Box>
          <Form.Item
            label="STORAGE UNIT TYPE"
            name="storage_unit_1_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="storage_unit_1_id"
                    labelField={"type"}
                    valueField={"storage_unit_id"}
                    options={storageUnits} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"no_of_storage_unit_1"} label="NO. OF STORAGE UNITS REQUIRED">
            <Input placeholder="0" />
          </Form.Item>
        </Box>
              </VStack>
              <VStack w={"1/2"}>
              <Box>
          <Form.Item
            label="PLANT"
            name="station_2_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="station_2_id"
                    labelField={"name"}
                    valueField={"station_id"}
                    options={stations} />
          </Form.Item>
          </Box>
          <Box>
          <Form.Item
            label="STORAGE UNIT TYPE"
            name="storage_unit_2_id"
            rules={[
              {
                required: true,
                message: "Please input!",
              },
            ]}
          >
            <VForm.Select field="storage_unit_2_id"
                    labelField={"type"}
                    valueField={"storage_unit_id"}
                    options={storageUnits} />
          </Form.Item>
        </Box>
        <Box>
          <Form.Item name={"no_of_storage_unit_2"} label="NO. OF STORAGE UNITS REQUIRED">
            <Input placeholder="0" />
          </Form.Item>
        </Box>
              </VStack>
            </HStack>
          
        <Box>
          <Form.Item name={"transfer_purpose"} label="Purpose">
            <TextArea rows={3} />
          </Form.Item>
        </Box>
     
      <Box>
        <Form.Item name={"vehicle_no"} label="VEHICLE NUMBER">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"driver_name"} label="DRIVER NAME">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"done_by"} label="LOADING DONE BY">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box>
        <Form.Item name={"gate_pass_no"} label="GATE PASS NUMBER">
          <Input placeholder="0" />
        </Form.Item>
      </Box>
      <Box mt={3}>
        <HStack m={"auto"} space={5} >
        <Box>
          
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    TRANSFER INITIATED
                  </Text>
                </Button>
              </Box>
              <Box >
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    TRANSFER DONE
                  </Text>
                </Button>
                </Form.Item>
              </Box>
        </HStack>
      
      </Box>
      </Form>
    </VStack>
  );
};

export default TransferForm;
