import { Box, Center, HStack, Text, VStack } from "native-base";
// import { DatePicker } from 'antd';
import {
  Button,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { ROUTES } from "../routes/my_routes";
import { Navigate, useNavigate } from "react-router-dom";
import VForm from "../components/antd_form";
const { TextArea } = Input;

const CreatePurchase = () => {
  const [form] = Form.useForm();
  const [vendors, setVendors] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [oils, setOils] = useState([]);
  const [oilList, setOilList] = useState([]);
  const [toaster, setToaster] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetch("http://localhost:5000/vendor")
      .then((response) => response.json())
      .then((data) => setVendors(data));
    // const vendo= [
    //   { name: 'Vendor1', vendor_id: 1 },
    //   { name: 'Vendor2', vendor_id: 2 },
    //   // Add more vendors as needed
    // ];
    // setVendors(vendo);
    fetch("http://localhost:5000/petrol-types")
      .then((response) => response.json())
      .then((data) => setOils(data));
  }, []);
  const handleSubmit = async (values) => {
    // const ne = {vendor_id:"4d653f93-fc70-4161-aefc-e395837b1f73",petrol_type_id:"0b4142a7-2717-4865-bcdd-052228d1ab73"}
    console.log("hg", values);
    // const val = {...values,...ne};
    // console.log(val);
        try {
          const response = await fetch("http://localhost:5000/transaction/purchase", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });

          const result = await response.json();
          console.log("Success:", result);
          setToaster('success');

        } catch (error) {
          console.error("Error:", error);
          setToaster('error');

        }
  };
  const [api, contextHolder] = notification.useNotification();
  const openSuccessNotificationWithIcon = (type) => {
    api[type]({
      message: "Onboarding Completed Successfully",
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
  };
  const openErrorNotificationWithIcon = (type) => {
    api[type]({
      message: "Onboarding Failed",
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
  };
  useEffect(() => {
    if (toaster == "success") {
      openSuccessNotificationWithIcon("success");
      // form.resetFields();
      navigate("/purchase-list");
    } else if (toaster == "error") {
      openErrorNotificationWithIcon("error");
    }
  }, [toaster]);
  useEffect(() => {
    let ve = [];
    vendors.map((v) => ve.push({ label: v.name, value: v.vendor_id }));
    setVendorList(ve);
    let oi = [];
    oils.map((o) => oi.push({ label: o.oil_name, value: o.petrol_type_id }));
    setOilList(oi);
  }, [vendors, oils]);
  console.log(vendorList);
  return (
    <Box>
      {/* <Header /> */}
      {contextHolder}
      <Box>
        {/* <Center _text={{ fontSize: 20, fontWeight: "bold" }}>
        Add New Oil Type
        </Center> */}
        <Form layout={"vertical"} onFinish={handleSubmit}>
          <Box p={5}>
            <Text fontSize={20} fontWeight={"bold"}>
              Create Purchase
            </Text>
            <VStack p={4}>
              {/* <HStack space={"5"}> */}
              <Box>
                <Form.Item
                  label="Vendor name"
                  name={"vendor_id"}
                  rules={[
                    {
                      // required: true,
                      message: "Please input!",
                    },
                  ]}
                >
                  <VForm.Select
                    field="vendor_id"
                    labelField={"label"}
                    valueField={"value"}
                    options={vendorList}
                  />
                  <Box flexDirection={"row-reverse"} p={1}>
                    <a href={ROUTES.VENDOR}>
                      <Text color={"#FF5F5F"}>+ Add New Vendor</Text>
                    </a>
                  </Box>
                </Form.Item>
              </Box>

              {/* </HStack> */}
              <HStack space={"2"}>
                <Box w={"60%"}>
                  <Form.Item
                    label="Oil Type"
                    name="petrol_type_id"
                    rules={[
                      {
                        // required: true,
                        message: "Please input!",
                      },
                    ]}
                  >
                    <VForm.Select field="petrol_type_id"
                    labelField={"label"}
                    valueField={"value"} options={oilList} />
                    <Box flexDirection={"row-reverse"} p={1}>
                      <a href={ROUTES.OIL}>
                        <Text color={"#FF5F5F"}>+ Add New Oil Type</Text>
                      </a>
                    </Box>
                  </Form.Item>
                </Box>
                <Box w={"40%"}>
                  <Form.Item name={"quantity"} label="Quantity (Kgs)">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"2"}>
                <Box w={"33%"}>
                  <Form.Item name={"mode_of_purchase"} label="Tank / Plant">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item name={"truck_no"} label="Truck No">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item name={"gate_pass_no"} label="Gate Pass No">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"2"}>
                <Box w={"25%"}>
                  <Form.Item name={"open_dip"} label="Open DIP">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"25%"}>
                  <Form.Item name={"open_stock"} label="Open Stock">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"25%"}>
                  <Form.Item name={"close_dip"} label="Close DIP">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"25%"}>
                  <Form.Item name={"close_stock"} label="Close Stock">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"2"}>
                <Box w={"33%"}>
                  <Form.Item name={"density"} label="Density">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item name={"temperature"} label="Temperature">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item name={"difference"} label="Difference">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
              <HStack space={"2"}>
                <Box w={"33%"}>
                  <Form.Item name={"due_on"} label="Due On">
                    <DatePicker />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item name={"sub_total"} label="Sub Total (INR)">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                <Box w={"33%"}>
                  <Form.Item
                    name={"grand_total"}
                    label="Grand Total With GST (INR)"
                  >
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
              </HStack>
            </VStack>
            {/* <Text fontSize={20} fontWeight={"bold"}>
              Optional
            </Text> */}
            <Box>
              <HStack direction={"row-reverse"} p={4}>
                <Box m={3}>
                  <Button
                    style={{
                      backgroundColor: "#FF5F5F",
                      width: "250px",
                      height: "50px",
                      // borderRadius: "50px",
                    }}
                    htmlType="submit"
                  >
                    <Text color={"white"} fontWeight={"bold"}>
                      Save
                    </Text>
                  </Button>
                </Box>
                <Box m={3}>
                  <Form.Item>
                    <Button
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "250px",
                        height: "50px",
                        // borderRadius: "50px",
                      }}
                      htmlType="submit"
                    >
                      <Text color={"#FF5F5F"} fontWeight={"bold"}>
                        Discard
                      </Text>
                    </Button>
                  </Form.Item>
                </Box>
              </HStack>
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default CreatePurchase;
