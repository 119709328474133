import { Box, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { Image } from 'antd';

const Header = () =>{
    const company_name = "Rasha Petroluem";
    return (
        <HStack space={4} ml={4} p={5} className="headerbar">
          <Image
        width={25}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        // src="./background.jpg"
      />
      {/* <img src="background.jpg" /> */}
      <span className="company_name">{company_name}</span></HStack>
    )
}
export default Header;
