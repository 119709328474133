import { Box, HStack } from 'native-base'
import React from 'react'
import SideMenuLayout from '../layout/sidemenu_layout'
import OnboardingEmployee from '../pages/onboarding_employee'
import Header from '../layout/Header'
import OnboardingClient from '../pages/onboarding_client'
import OnboardingVendor from '../pages/onboarding_vendor'
import PurchaseList from '../pages/purchase_list'
import SalesList from '../pages/sales_list'

const SalesModule = () => {
  return (
    <div>
      <Header />
      <HStack  h={"900px"}>
        <Box>
        <SideMenuLayout />
        </Box>
       <Box w={"75%"}>
       <SalesList />
       </Box>
        
      </HStack>
    </div>
  )
}

export default SalesModule
