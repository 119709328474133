import React, { useState } from 'react'
import LoadingForm from './loading_form'
import { Box, Center } from 'native-base'
import { Form, Radio } from 'antd';
import UnloadingForm from './unloading_form';
import TransferForm from './transfer_form';
import LoadingList from './loading_list';
import TransferList from './transfer_list';
import UnloadingList from './unloading_list';

const TransferMain = () => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('unloading');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
        
      };
    
  return (
    <Box p={5}>
      <Center>
      <Form
        form={form}
      initialValues={{ layout: formLayout }}
      onValuesChange={onFormLayoutChange}>
<Form.Item name="layout">
        <Radio.Group value={formLayout}>
          <Radio.Button value="unloading">Unloading</Radio.Button>
          <Radio.Button value="loading">Loading</Radio.Button>
          <Radio.Button value="transfer">Transfer</Radio.Button>
        </Radio.Group>
      </Form.Item>
      </Form>
      </Center>
        {/* <Box>{formLayout === "unloading" ? <UnloadingForm /> : formLayout === "loading" ? <LoadingForm /> : <TransferForm />  }</Box> */}
        <Box>{formLayout === "unloading" ? <UnloadingList /> : formLayout === "loading" ? <LoadingList /> : <TransferList />  }</Box>
    </Box>
  )
}

export default TransferMain
