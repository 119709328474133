import React from "react";
import { Box, HStack, Text } from "native-base";

const SidebarFooter = (props) => {
  return (
    <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
      <HStack>
        <Text color="#ffffff" fontSize={12}>
          Powered By{" "}
        </Text>

        <a href="https://tkpropeller.com/" target="blank">
          <img
            height="20"
            alt="dharani"
            // src="https://nt-agriosp-assets.s3.ap-south-1.amazonaws.com/ce5c5065-575d-4786-9cf8-f1c24bc2ad5c.png"
          />
        </a>
      </HStack>
    </Box>
  );
};

export default SidebarFooter;
