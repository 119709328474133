
import admin_layout from "../layout/admin_layout";
import SideMenuLayout from "../layout/sidemenu_layout";
import ClientListModule from "../modules/client_list_module";
import ClientModule from "../modules/client_module";
import DashboardModule from "../modules/dashboard_module";
import EmpListModule from "../modules/employee_list_module";
import EmployeeModule from "../modules/employee_module";
import LoadingListModule from "../modules/loading_list_module";
import LogisticModule from "../modules/logistic_module";
import LogisticsServiceModule from "../modules/logistics_list_module";
import OilListModule from "../modules/oil_list_module";
import OilModule from "../modules/oil_module";
import PurchaseCreationModule from "../modules/purchase_creation_module";
import PurchaseModule from "../modules/purchase_module";
import SalesCreationModule from "../modules/sales_creation_module";
import SalesModule from "../modules/sales_module";
import TransferListModule from "../modules/transfer_list_module";
import TransferModule from "../modules/transfer_module";
import VendorListModule from "../modules/vendor_list_module";
import VendorModule from "../modules/vendor_module";
import Home from "../pages/admin_login";
import ClientList from "../pages/client_list";
import CreateSales from "../pages/create_sales";
import DashboardPage from "../pages/dashboard";
import OnboardingClient from "../pages/onboarding_client";
import OnboardingEmployee from "../pages/onboarding_employee";
import OnboardingLogistics from "../pages/onboarding_logistics";
import OnboardingOil from "../pages/onboarding_oil";
import OnboardingVendor from "../pages/onboarding_vendor";
import PurchaseList from "../pages/purchase_list";
import SalesList from "../pages/sales_list";
import transferMain from "../pages/transfer_main";

export const ROUTES = {
  HOME: "/",
  PURCHASE: "/purchase-list",
  SALES: "/sales-list",
  CLIENT: "/onboarding-client",
  VENDOR: "/onboarding-vendor",
  LOGISTICS: "/onboarding-logistics",
  OIL: "/onboarding-oil",
  EMPLOYEE: "/onboarding-employee",
  CREATE_SALES: "/create-sales",
  CREATE_PURCHASE: "/create-purchase",
  TRANSFER: "/transfer",
  CLIENT_LIST: "/client-list",
  VENDOR_LIST: "/vendor-list",
  EMPLOYEE_LIST: "/employee-list",
  LOGISTICS_LIST: "/logistic-list",
  OIL_LIST: "/oil-list",
  TRANSFER_LIST: "/transfer-list",
  LOADING_LIST: "/loading-list",
  // DASHBOARD: "/home"

};

const myRoutes = [
    {
        key: "home",
        path: ROUTES.HOME,
        component: DashboardModule,
    },
    {
      key: "onboarding-vendor",
      path: ROUTES.VENDOR,
      component: VendorModule,
    },
    {
      key: "onboarding-client",
      path: ROUTES.CLIENT,
      component: ClientModule,
    },
    {
      key: "onboarding-logistics",
      path: ROUTES.LOGISTICS,
      component: LogisticModule,
    },
    {
      key: "onboarding-oil",
      path: ROUTES.OIL,
      component: OilModule,
    },
    {
      key: "onboarding-employee",
      path: ROUTES.EMPLOYEE,
      component: EmployeeModule,
    },
    {
      key: "purchase-list",
      path: ROUTES.PURCHASE,
      component: PurchaseModule,
    },
    {
      key: "sales-list",
      path: ROUTES.SALES,
      component: SalesModule,
    },
    {
      key: "create-sales",
      path: ROUTES.CREATE_SALES,
      component: SalesCreationModule,
    },
    {
      key: "create-purchase",
      path: ROUTES.CREATE_PURCHASE,
      component: PurchaseCreationModule,
    },
    {
      key: "transfer",
      path: ROUTES.TRANSFER,
      component: TransferModule,
    },
    {
      key: "client-list",
      path: ROUTES.CLIENT_LIST,
      component: ClientListModule,
    },
    {
      key: "vendor-list",
      path: ROUTES.VENDOR_LIST,
      component: VendorListModule,
    },
    {
      key: "employee-list",
      path: ROUTES.EMPLOYEE_LIST,
      component: EmpListModule,
    },
    {
      key: "logistic-list",
      path: ROUTES.LOGISTICS_LIST,
      component: LogisticsServiceModule,
    },
    {
      key: "oil-list",
      path: ROUTES.OIL_LIST,
      component: OilListModule,
    },
    {
      key: "transfer-list",
      path: ROUTES.TRANSFER_LIST,
      component: TransferListModule,
    },
    {
      key: "loading-list",
      path: ROUTES.LOADING_LIST,
      component: LoadingListModule,
    },
  {
    key: "admin",
    component: SideMenuLayout,
    authenticate: false,
    children: [
      // {
      //   path: ROUTES.LOGIN,
      //   page_key: "login_form",
      //   component: Login,
      //   authenticate: false,
      // {
      //   path: ROUTES.HOME,
      //   exact: true,
      //   page_key: "home",
      //   component: DashboardPage,
      //   authenticate: false,
      // },
      // {
      //   path: ROUTES.CLIENT,
      //   exact: true,
      //   page_key: "client",
      //   component: OnboardingClient,
      //   authenticate: false,
      // },
    ],
  },
];

export default myRoutes;
