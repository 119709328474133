import { Box, Center, HStack, Text, VStack } from 'native-base'
// import { DatePicker } from 'antd';
import { Button, DatePicker, Form, Input, Radio, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { ROUTES } from '../routes/my_routes';
const { TextArea } = Input;
const OnboardingOil = () => {
    const [form] = Form.useForm();
    const [oil_types,setOilTypes] = useState([]);
    const [vendors,setVendors] = useState([]);
    const [toaster,setToaster] = useState('');
    useEffect(()=>{
      fetch("http://localhost:5000/petrol-types").then((response) => response.json()).then(data => setOilTypes(data));
      console.log(oil_types);
      fetch("http://localhost:5000/vendor").then((response) => response.json()).then(data => setVendors(data));
      console.log("vendors",vendors);
    },[])
    const [api, contextHolder] = notification.useNotification();
    const openSuccessNotificationWithIcon = (type) => {
      api[type]({
        message: 'Onboarding Completed Successfully',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    const openErrorNotificationWithIcon = (type) => {
      api[type]({
        message: 'Onboarding Failed',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
    };
    // console.log(oil_types);
    const handleSubmit = async(values) => {
      console.log("hg",values);
        try {
          const response = await fetch("http://localhost:5000/petrol-types", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
      
          const result = await response.json();
          setToaster('success');
          console.log("Success:", result);
         
        } catch (error) {
          console.error("Error:", error);
          setToaster('error');
        }
    }
    // openSuccessNotificationWithIcon('success');
    useEffect(()=>{
      if(toaster == 'success'){
        openSuccessNotificationWithIcon('success');
      }else if(toaster == 'error'){
        openErrorNotificationWithIcon('error');
      }
    },[toaster])
    const [vendorList,setVendorList] = useState([]);
    useEffect(()=>{
      let ve = [];
      vendors.map((v)=>ve.push({label:v.name,value:v.vendor_id}))
      setVendorList(ve);
    },[vendors])
    console.log(vendorList);
  return (
    <Box>
       {contextHolder}
      {/* <Header /> */}
      <Box>
        <Center _text={{ fontSize: 20, fontWeight: "bold" }}>
        Add New Oil Type
        </Center>
        <Form layout={"vertical"} onFinish={handleSubmit}>
          <Box p={5}>
            <Text fontSize={20} fontWeight={"bold"}>
            Details
            </Text>
            <VStack p={4}>
              {/* <HStack space={"5"}> */}
                <Box>
                  <Form.Item name={"name"} label="Name">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                
                
              {/* </HStack> */}
              <HStack space={"1/2"}>
              <Box>
                  <Form.Item
                    name="oil_type"
                    label="Oil Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="liquid">Liquid</Radio.Button>
                      <Radio.Button value="gas">Gas</Radio.Button>
                      <Radio.Button value="semi">Semi</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={"oil_code"} label="Oil Code">
                    <Input placeholder="Type" />
                  </Form.Item>
                </Box>
                
              </HStack>
              <HStack space={"1/6"} >
                {/* <Box> */}
                  <Form.Item name={"density"} label="Density">
                    <Input placeholder="Type" />
                  </Form.Item>
                {/* </Box> */}
                {/* <Box> */}
                  <Form.Item name={"quality"} label="Quality">
                    <Input placeholder="Type" />
                  </Form.Item>
                {/* </Box> */}
                {/* <Box> */}
                    <Form.Item name={"doi"} label="Date of incorporation">
                      <DatePicker />
                    </Form.Item>
                  {/* </Box> */}
                
              </HStack>

            </VStack>
            <Text fontSize={20} fontWeight={"bold"}>
              Optional
            </Text>
            <Box>
              <VStack p={4}>
               
                  <Box>
                  <Form.Item
                  label="Vendor name"
                  name="vendor"
                  rules={[
                    {
                      // required: true,
                      message: "Please input!",
                    },
                  ]}
                >
                  <Select options={vendorList} />
                  <Box flexDirection={"row-reverse"} p={1}>
                  <a href={ROUTES.VENDOR}><Text color={"#FF5F5F"}>+ Add New Vendor</Text></a>
                  </Box>
                </Form.Item>
                  </Box>
                  <Box>
                    <Form.Item name={"purpose"} label="Purpose">
                    <TextArea rows={3} />
                    </Form.Item>
                  </Box>
                
                
              </VStack>

              
              <VStack p={4}>
            
                <Text color={"#FF5F5F"} fontSize={"20px"}>Certification & Self Declaration</Text>
              <Box p={4}>
              <Text mb={3}>
                I/We agree to comply with strict adherence to the Company’s
                policies and procedures, and other applicable Statutory Laws,
                throughout the term of service.
              </Text>
              <Form.Item name={"auth"}>
              <Radio value="auth">
                {" "}
                To be signed by Authorised Representative{" "}
              </Radio>
              </Form.Item>
              </Box>
              <Box m={"auto"}>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#FF5F5F",
                    width: "250px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                  htmlType="submit"
                >
                  <Text color={"white"} fontWeight={"bold"}>
                    Submit
                  </Text>
                </Button>
                </Form.Item>
              </Box>
              </VStack>
             
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default OnboardingOil
