import { Box, HStack } from 'native-base'
import React from 'react'
import SideMenuLayout from '../layout/sidemenu_layout'
import OnboardingEmployee from '../pages/onboarding_employee'
import Header from '../layout/Header'
import OnboardingClient from '../pages/onboarding_client'
import ClientList from '../pages/client_list'
import VendorList from '../pages/vendor_list'
import EmpList from '../pages/employee_list'

const EmpListModule = () => {
  return (
    <div>
      <Header />
      <HStack h={"900px"}>
        <Box>
        <SideMenuLayout />
        </Box>
       <Box w={"70%"}>
       <EmpList />
       </Box>
        
      </HStack>
    </div>
  )
}

export default EmpListModule
