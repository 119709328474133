import React from 'react';
import DashboardTile from '../components/dashboard_tile';
import { Box, HStack, Pressable, VStack } from 'native-base';


const DashboardPage = () => {
    const handleClick = () => {
        console.log("Clecked")
    }
  return (
    <Box className="dashboard-page">
        <h2>Sales</h2>
     <HStack space={4}>
     <DashboardTile onClick={handleClick} title="Total Sales" value="$10,000" color1={"#43c6ac"} color2={"#f8ffae"}/>
      <DashboardTile title="Total Orders" value="100" color1={"#ffafbd"} color2={"#ffc3a0"}/>
      <DashboardTile title="New Clients" value="50" color1={"#f3904f"} color2={"#ee0979"} />
     </HStack>
     <h2>Purchase</h2>
     <HStack space={4}>
     <DashboardTile title="Total Purchases" value="$1,000,000" color1={"#43c6ac"} color2={"#f8ffae"} />
      <DashboardTile title="Total Orders" value="30" color1={"#ffafbd"} color2={"#ffc3a0"}/>
      <DashboardTile title="New Vendors" value="10" color1={"#f3904f"} color2={"#ee0979"} />
     </HStack>  
     <h2>Transfer</h2>
     <HStack space={4}>
     <DashboardTile title="Total Transfers" value="130" color1={"#43c6ac"} color2={"#f8ffae"}/>
      <DashboardTile title="Total Unloading" value="30" color1={"#ffafbd"} color2={"#ffc3a0"}/>
      <DashboardTile title="Total Loading" value="100" color1={"#f3904f"} color2={"#ee0979"} />
     </HStack>  
    </Box>
  );
};

export default DashboardPage;
